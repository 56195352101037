.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 1000;
}

.modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px;
    z-index: 1000;
    border: 3px solid white;
    background-color: black;
    border-radius: 20px;
    display: grid;
    width: 50%;
    min-width: 300px;
    text-align: center;
    
    max-height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-title {
    text-align: center;
    margin-bottom: 20px;
}

.modal-close-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    width: 40px;
    border: none;
    background: none;
    border-top: none;
    border-right: none;
    transition: all 0.2s linear;
    outline: none;
}

.modal-close-button:focus {
    outline: none;
}

.modal-title {
    font-size: 20px;
}

.xmark {
    margin: 10px;
    color: white;
    font-size: 1.5rem;
    transition: all 0.1s linear;
    color: rgb(232,12,139);
}

.xmark:hover {
    /* color: grey; */
    color: white;
}

.modal-overlay select {
    margin-bottom: 20px;
    font-weight: bolder;
    width: 70%;
    justify-self: center;
    max-width: 300px;
}

.modal-add-button {
    background-color: rgb(232,12,139);
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    transition: all 0.1s linear;
    justify-self: center;

}

.modal-add-button:hover {
    background-color: rgba(232,12,139,0.4);
    color: white;
}

.modal-add-button:focus {
    outline: none;
}




