.footer {
    background-color: #242424;
    color: white;

    /* position: relative; */
    width: 100%;

    bottom: 0;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    margin-top: 50px;
}

.footer ul {
    list-style: none;
}

.footer-container {
    margin: auto;
}

.footer-row {
    display: flex;
    flex-wrap: wrap;
}

.footer-col {
    padding: 0 15px;
    margin: 50px;
}

.footer-col h4 {
    font-size: 18px;
    margin-bottom: 30px;
    position: relative;
    font-weight: normal;
}

.footer-col ul li:not(:last-child) {
    margin-bottom: 10px;
}

.footer-col ul li a {
    font-size: 16px;
    color: white;
    text-decoration: none;
    display: block;
    transition: all 0.2s ease;
}

.footer-col ul li a:hover {
    color: rgba(255,255,255,0.5);
}

.footer-col .social-links a {
    display: inline-block;
    height: 40px;
    width: 40px;
    background-color: grey;
    margin: 0 10px 10px 0; 
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: white;
    transition: all 0.5s ease;
}

.footer-col .social-links a:hover {
    color: #24262b;
    background-color: white;
}

.footer-copyright {
    font-size: 14px;
}

@media(max-width: 760px) {
    .footer-col {
        width: 50%;
        margin-bottom: 30px;
    }
}

@media(max-width: 570px) {
    .footer-col {
        width: 100%;
    }
}
