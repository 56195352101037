.contact-container {
    width: 100%;
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    padding: 2vw 4vw;
    width: 90%;
    max-width: 600px;
    border-radius: 10px;
    border: 3px solid white;
    /* background-color: rgb(12, 12, 12); */
    /* background-color: #242424; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.thanks {
    color: white;
}

.contact-form h3 {
    font-weight: normal;
    margin-bottom: 20px;
}

.contact-form input, .contact-form textarea {
    font-size: 18px;
    font-weight: bold;
    border: none;
    margin: 10px 0;
    padding: 10px;
    outline: none;
    background-color: #f5f5f5;
    border-radius: 5px;
}

.submit-button {
    padding: 15px;
    background: rgb(232,12,139);
    color: white;
    font-size: 18px;
    border: none;
    outline: none;
    cursor: pointer;
    width: 6em;
    margin: 20px auto 0;
    border-radius: 10px;
    transition: all 0.1s linear;
}

.contact-form button:hover {
    background: rgba(232,12,139,0.5);
}