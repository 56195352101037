.header-logo-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 140px; */
    margin: 10px;
}

.header-logo {
    width: 24%;
}