.product-link {
    color: rgb(41, 168, 223);
    transition: all 0.1s linear;
}

.product-link:hover {
    color: rgb(26, 80, 104);
    text-decoration: none;
}

.product-img {
    width: 100%;
}

.product-container {
    display: flex;
    flex-direction: column;
    color: white; 
    border: 2px solid white;
    border-radius: 5px;
    text-align: center;
    width: auto;
    padding: 20px;
    max-width: 400px;
    align-items: center;

}

.product-title {
    padding: 10px;
    font-size: 20px;
    color: rgb(41, 168, 223);
}

.product-desc {
    font-size: 14px;
    margin-bottom: 20px;
}

.add-button {
    background-color: rgb(41, 168, 223);
    border: none;
    padding: 10px;
    border-radius: 5px;
    margin-top: auto;
    width: 180px;
    transition: all 0.1s linear;
}

.add-button:hover {
    background-color: rgb(26, 80, 104);
    color: white;
}