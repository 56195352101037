* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

body {
  background-color: black;
  color: white;
}

.header {
  text-align: center;
}

.with-animated-underline {
  transition: all .1s linear;
  display: inline-block;
  position: relative;
  padding-bottom: .4rem;
}

.with-animated-underline::before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  transform: scaleX(0);
  transition: transform 0.25s ease;
}

.with-animated-underline-larger::before {
  height: 6px;
}

.with-animated-underline:hover::before {
  transform: scaleX(1);
}

.red::before {
  background-color: rgb(232,12,139);
}

.blue::before {
  background-color: rgb(41, 168, 223)
}

.yellow::before {
  background-color: rgb(243,236,31);
}

.section-title {
  font-size: 40px;
}