.cart-entry {
    border: 1px solid white;
    border-radius: 5px;
    padding: 20px;
    width: 25%;
    min-width: 300px;
    margin: 12px;
    position: relative;
}

.cart-entry-name {
    font-size: 18px;
    margin-bottom: 5px;
}

.cart-entry-desc {
    font-size: 16px;
}

.specs-list {
    list-style-position: inside;
}

.product-remove-button {
    position: absolute;
    top: 0;
    right: 0;
    background-color: black;
    border: none;
    background: none;
    font-size: 0;
}

.product-remove-button:focus {
    outline: none;
}