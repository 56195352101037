.carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.carousel .slide {
    align-self: center;
}

.carousel .slide img {
}

.carousel-image {
    margin: 0 auto;
    max-width: 1000px;
}

