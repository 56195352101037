* {
    text-align: center;
}

.products-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;

}

.cart-container-title {
    padding: 20px;
    font-size: 20px;
}

