.section-title {
    font-size: 20px;
}

.about-section {
    display: grid;
    /* grid-template-columns: repeat(3, 400px); */
    grid-template-columns: repeat(auto-fit, 20rem);
    width: 100%;
    margin: 0 auto;
    justify-content: center;

}

.clients-section {
    margin: 100px;
    text-align: center;
    justify-content: center;
}

.clients-title {
    margin-bottom: 30px;
}

.client-logos {
    display: grid;
    width: 100%;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 10rem);
    /* grid-template-columns: repeat(5, 13rem); */
    grid-template-rows: 1fr 1fr;
    margin: 0 auto;
    row-gap: 2rem;
}

.a-propos {
    margin: auto;
    margin-bottom: 100px;

    width: 70%;
    align-items: center;
    align-self: center;
}

.a-propos-text {
    margin-top: 10px;
}

.client-logo {
    height: 90px;
    align-self: center;
    justify-self: center;
    transition: transform .2s;
}

.client-logo:hover {
    transform: scale(1.1);
}

/* about section styles */

.img-container {
    justify-self: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 300px;
}

.img-title {
    font-size: 20px;
}

.about-img {
    border-radius: 10px;
}

.img-desc {
    margin: 10px;
}
